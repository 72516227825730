export const countries = [
  {
    name: "Bahamas",
    name_official: "The Commonwealth of The Bahamas",
    alpha_2: "BS",
    alpha_3: "BHS",
    country_code: "044",
    currency_name: "Bahamian Dollar",
    currency_code: "BSD",
    currency_number: "044",
    country_code_iso: "ISO 3166-2:BS",
    ccTLD: ".bs",
    phone_code: "+1242"
  },
  {
    name: "Albania",
    name_official: "The Republic of Albania",
    alpha_2: "AL",
    alpha_3: "ALB",
    country_code: "008",
    currency_name: "Lek",
    currency_code: "ALL",
    currency_number: "008",
    country_code_iso: "ISO 3166-2:AL",
    ccTLD: ".al",
    phone_code: "+355"
  },
  {
    name: "Myanmar ",
    name_official: "The Republic of the Union of Myanmar",
    alpha_2: "MM",
    alpha_3: "MMR",
    country_code: "104",
    currency_name: "Kyat",
    currency_code: "MMK",
    currency_number: "104",
    country_code_iso: "ISO 3166-2:MM",
    ccTLD: ".mm",
    phone_code: "+95"
  },
  {
    name: "Burundi",
    name_official: "The Republic of Burundi",
    alpha_2: "BI",
    alpha_3: "BDI",
    country_code: "108",
    currency_name: "Burundi Franc",
    currency_code: "BIF",
    currency_number: "108",
    country_code_iso: "ISO 3166-2:BI",
    ccTLD: ".bi",
    phone_code: "+257"
  },
  {
    name: "Cambodia",
    name_official: "The Kingdom of Cambodia",
    alpha_2: "KH",
    alpha_3: "KHM",
    country_code: "116",
    currency_name: "Riel",
    currency_code: "KHR",
    currency_number: "116",
    country_code_iso: "ISO 3166-2:KH",
    ccTLD: ".kh",
    phone_code: "+855"
  },
  {
    name: "Algeria",
    name_official: "The People's Democratic Republic of Algeria",
    alpha_2: "DZ",
    alpha_3: "DZA",
    country_code: "012",
    currency_name: "Algerian Dinar",
    currency_code: "DZD",
    currency_number: "012",
    country_code_iso: "ISO 3166-2:DZ",
    ccTLD: ".dz",
    phone_code: "+213"
  },
  {
    name: "Canada",
    name_official: "Canada",
    alpha_2: "CA",
    alpha_3: "CAN",
    country_code: "124",
    currency_name: "Canadian Dollar",
    currency_code: "CAD",
    currency_number: "124",
    country_code_iso: "ISO 3166-2:CA",
    ccTLD: ".ca",
    phone_code: "+1"
  },
  {
    name: "Cabo Verde ",
    name_official: "The Republic of Cabo Verde",
    alpha_2: "CV",
    alpha_3: "CPV",
    country_code: "132",
    currency_name: "Cabo Verde Escudo",
    currency_code: "CVE",
    currency_number: "132",
    country_code_iso: "ISO 3166-2:CV",
    ccTLD: ".cv",
    phone_code: "+238"
  },
  {
    name: "Cayman Islands",
    name_official: "The Cayman Islands",
    alpha_2: "KY",
    alpha_3: "CYM",
    country_code: "136",
    currency_name: "Cayman Islands Dollar",
    currency_code: "KYD",
    currency_number: "136",
    country_code_iso: "ISO 3166-2:KY",
    ccTLD: ".ky",
    phone_code: "+1345"
  },
  {
    name: "Sri Lanka",
    name_official: "The Democratic Socialist Republic of Sri Lanka",
    alpha_2: "LK",
    alpha_3: "LKA",
    country_code: "144",
    currency_name: "Sri Lanka Rupee",
    currency_code: "LKR",
    currency_number: "144",
    country_code_iso: "ISO 3166-2:LK",
    ccTLD: ".lk",
    phone_code: "+94"
  },
  {
    name: "Chile",
    name_official: "The Republic of Chile",
    alpha_2: "CL",
    alpha_3: "CHL",
    country_code: "152",
    currency_name: "Chilean Peso",
    currency_code: "CLP",
    currency_number: "152",
    country_code_iso: "ISO 3166-2:CL",
    ccTLD: ".cl",
    phone_code: "+56"
  },
  {
    name: "China",
    name_official: "The People's Republic of China",
    alpha_2: "CN",
    alpha_3: "CHN",
    country_code: "156",
    currency_name: "Yuan Renminbi",
    currency_code: "CNY",
    currency_number: "156",
    country_code_iso: "ISO 3166-2:CN",
    ccTLD: ".cn",
    phone_code: "+86"
  },
  {
    name: "Colombia",
    name_official: "The Republic of Colombia",
    alpha_2: "CO",
    alpha_3: "COL",
    country_code: "170",
    currency_name: "Colombian Peso",
    currency_code: "COP",
    currency_number: "170",
    country_code_iso: "ISO 3166-2:CO",
    ccTLD: ".co",
    phone_code: "+57"
  },
  {
    name: "Comoros",
    name_official: "The Union of the Comoros",
    alpha_2: "KM",
    alpha_3: "COM",
    country_code: "174",
    currency_name: "Comoro Franc",
    currency_code: "KMF",
    currency_number: "174",
    country_code_iso: "ISO 3166-2:KM",
    ccTLD: ".km",
    phone_code: "+269"
  },
  {
    name: "Costa Rica",
    name_official: "The Republic of Costa Rica",
    alpha_2: "CR",
    alpha_3: "CRI",
    country_code: "188",
    currency_name: "Costa Rican Colon",
    currency_code: "CRC",
    currency_number: "191",
    country_code_iso: "ISO 3166-2:CR",
    ccTLD: ".cr",
    phone_code: "+506"
  },
  {
    name: "Croatia",
    name_official: "The Republic of Croatia",
    alpha_2: "HR",
    alpha_3: "HRV",
    country_code: "191",
    currency_name: "Kuna",
    currency_code: "HRK",
    currency_number: "192",
    country_code_iso: "ISO 3166-2:HR",
    ccTLD: ".hr",
    phone_code: "+385"
  },
  {
    name: "Cyprus",
    name_official: "The Republic of Cyprus",
    alpha_2: "CY",
    alpha_3: "CYP",
    country_code: "196",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "203",
    country_code_iso: "ISO 3166-2:CY",
    ccTLD: ".cy",
    phone_code: "+357"
  },
  {
    name: "Benin",
    name_official: "The Republic of Benin",
    alpha_2: "BJ",
    alpha_3: "BEN",
    country_code: "204",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "208",
    country_code_iso: "ISO 3166-2:BJ",
    ccTLD: ".bj",
    phone_code: "+229"
  },
  {
    name: "Greece",
    name_official: "The Hellenic Republic",
    alpha_2: "GR",
    alpha_3: "GRC",
    country_code: "300",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "208",
    country_code_iso: "ISO 3166-2:GR",
    ccTLD: ".gr",
    phone_code: "+30"
  },
  {
    name: "Estonia",
    name_official: "The Republic of Estonia",
    alpha_2: "EE",
    alpha_3: "EST",
    country_code: "233",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "208",
    country_code_iso: "ISO 3166-2:EE",
    ccTLD: ".ee",
    phone_code: "+372"
  },
  {
    name: "Dominica",
    name_official: "The Commonwealth of Dominica",
    alpha_2: "DM",
    alpha_3: "DMA",
    country_code: "212",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "214",
    country_code_iso: "ISO 3166-2:DM",
    ccTLD: ".dm",
    phone_code: "+1767"
  },
  {
    name: "Ecuador",
    name_official: "The Republic of Ecuador",
    alpha_2: "EC",
    alpha_3: "ECU",
    country_code: "218",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "222",
    country_code_iso: "ISO 3166-2:EC",
    ccTLD: ".ec",
    phone_code: "+593"
  },
  {
    name: "Equatorial Guinea",
    name_official: "The Republic of Equatorial Guinea",
    alpha_2: "GQ",
    alpha_3: "GNQ",
    country_code: "226",
    currency_name: "CFA Franc BEAC",
    currency_code: "XAF",
    currency_number: "230",
    country_code_iso: "ISO 3166-2:GQ",
    ccTLD: ".gq",
    phone_code: "+240"
  },
  {
    name: "Ethiopia",
    name_official: "The Federal Democratic Republic of Ethiopia",
    alpha_2: "ET",
    alpha_3: "ETH",
    country_code: "231",
    currency_name: "Ethiopian Birr",
    currency_code: "ETB",
    currency_number: "232",
    country_code_iso: "ISO 3166-2:ET",
    ccTLD: ".et",
    phone_code: "+251"
  },
  {
    name: "Faroe Islands",
    name_official: "The Faroe Islands",
    alpha_2: "FO",
    alpha_3: "FRO",
    country_code: "234",
    currency_name: "Faroese króna",
    currency_code: "FOK",
    currency_number: "238",
    country_code_iso: "ISO 3166-2:FO",
    ccTLD: ".fo",
    phone_code: "+298"
  },
  {
    name: "Falkland Islands [Malvinas]",
    name_official: "The Falkland Islands",
    alpha_2: "FK",
    alpha_3: "FLK",
    country_code: "238",
    currency_name: "Falkland Islands Pound",
    currency_code: "FKP",
    currency_number: "238",
    country_code_iso: "ISO 3166-2:FK",
    ccTLD: ".fk",
    phone_code: "+500"
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    name_official: "South Georgia and the South Sandwich Islands",
    alpha_2: "GS",
    alpha_3: "SGS",
    country_code: "239",
    currency_name: "Falkland Islands pound",
    currency_code: "FKP",
    currency_number: "242",
    country_code_iso: "ISO 3166-2:GS",
    ccTLD: ".gs",
    phone_code: "+500"
  },
  {
    name: "French Polynesia",
    name_official: "French Polynesia",
    alpha_2: "PF",
    alpha_3: "PYF",
    country_code: "258",
    currency_name: "CFP Franc",
    currency_code: "XPF",
    currency_number: "262",
    country_code_iso: "ISO 3166-2:PF",
    ccTLD: ".pf",
    phone_code: "+689"
  },
  {
    name: "Georgia",
    name_official: "Georgia",
    alpha_2: "GE",
    alpha_3: "GEO",
    country_code: "268",
    currency_name: "Lari",
    currency_code: "GEL",
    currency_number: "270",
    country_code_iso: "ISO 3166-2:GE",
    ccTLD: ".ge",
    phone_code: "+995"
  },
  {
    name: "Ghana",
    name_official: "The Republic of Ghana",
    alpha_2: "GH",
    alpha_3: "GHA",
    country_code: "288",
    currency_name: "Ghana Cedi",
    currency_code: "GHS",
    currency_number: "292",
    country_code_iso: "ISO 3166-2:GH",
    ccTLD: ".gh",
    phone_code: "+233"
  },
  {
    name: "Argentina",
    name_official: "The Argentine Republic",
    alpha_2: "AR",
    alpha_3: "ARG",
    country_code: "032",
    currency_name: "Argentine Peso",
    currency_code: "ARS",
    currency_number: "032",
    country_code_iso: "ISO 3166-2:AR",
    ccTLD: ".ar",
    phone_code: "+54"
  },
  {
    name: "Guam",
    name_official: "The Territory of Guam",
    alpha_2: "GU",
    alpha_3: "GUM",
    country_code: "316",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "320",
    country_code_iso: "ISO 3166-2:GU",
    ccTLD: ".gu",
    phone_code: "+1671"
  },
  {
    name: "Guatemala",
    name_official: "The Republic of Guatemala",
    alpha_2: "GT",
    alpha_3: "GTM",
    country_code: "320",
    currency_name: "Quetzal",
    currency_code: "GTQ",
    currency_number: "324",
    country_code_iso: "ISO 3166-2:GT",
    ccTLD: ".gt",
    phone_code: "+502"
  },
  {
    name: "Guinea",
    name_official: "The Republic of Guinea",
    alpha_2: "GN",
    alpha_3: "GIN",
    country_code: "324",
    currency_name: "Guinea Franc",
    currency_code: "GNF",
    currency_number: "328",
    country_code_iso: "ISO 3166-2:GN",
    ccTLD: ".gn",
    phone_code: "+224"
  },
  {
    name: "Guyana",
    name_official: "The Co-operative Republic of Guyana",
    alpha_2: "GY",
    alpha_3: "GUY",
    country_code: "328",
    currency_name: "Guyana Dollar",
    currency_code: "GYD",
    currency_number: "332",
    country_code_iso: "ISO 3166-2:GY",
    ccTLD: ".gy",
    phone_code: "+592"
  },
  {
    name: "Heard Island and McDonald Islands",
    name_official: "The Territory of Heard Island and McDonald Islands",
    alpha_2: "HM",
    alpha_3: "HMD",
    country_code: "334",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "340",
    country_code_iso: "ISO 3166-2:HM",
    ccTLD: ".hm",
    phone_code: "+6723"
  },
  {
    name: "Honduras",
    name_official: "The Republic of Honduras",
    alpha_2: "HN",
    alpha_3: "HND",
    country_code: "340",
    currency_name: "Lempira",
    currency_code: "HNL",
    currency_number: "344",
    country_code_iso: "ISO 3166-2:HN",
    ccTLD: ".hn",
    phone_code: "+504"
  },
  {
    name: "Hungary",
    name_official: "Hungary",
    alpha_2: "HU",
    alpha_3: "HUN",
    country_code: "348",
    currency_name: "Indian Rupee",
    currency_code: "INR",
    currency_number: "352",
    country_code_iso: "ISO 3166-2:HU",
    ccTLD: ".hu",
    phone_code: "+36"
  },
  {
    name: "Iceland",
    name_official: "Iceland",
    alpha_2: "IS",
    alpha_3: "ISL",
    country_code: "352",
    currency_name: "Iceland Krona",
    currency_code: "ISK",
    currency_number: "356",
    country_code_iso: "ISO 3166-2:IS",
    ccTLD: ".is",
    phone_code: "+354"
  },
  {
    name: "Hong Kong",
    name_official: "The Hong Kong Special Administrative Region of China",
    alpha_2: "HK",
    alpha_3: "HKG",
    country_code: "344",
    currency_name: "Hong Kong Dollar",
    currency_code: "HKD",
    currency_number: "356",
    country_code_iso: "ISO 3166-2:HK",
    ccTLD: ".hk",
    phone_code: "+852"
  },
  {
    name: "Namibia",
    name_official: "The Republic of Namibia",
    alpha_2: "NA",
    alpha_3: "NAM",
    country_code: "516",
    currency_name: "Namibia Dollar",
    currency_code: "NAD",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:NA",
    ccTLD: ".na",
    phone_code: "+264"
  },
  {
    name: "Gibraltar",
    name_official: "Gibraltar",
    alpha_2: "GI",
    alpha_3: "GIB",
    country_code: "292",
    currency_name: "Gibraltar Pound",
    currency_code: "GIP",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:GI",
    ccTLD: ".gi",
    phone_code: "+350"
  },
  {
    name: "Haiti",
    name_official: "The Republic of Haiti",
    alpha_2: "HT",
    alpha_3: "HTI",
    country_code: "332",
    currency_name: "Gourde",
    currency_code: "HTG",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:HT",
    ccTLD: ".ht",
    phone_code: "+509"
  },
  {
    name: "Australia ",
    name_official: "The Commonwealth of Australia",
    alpha_2: "AU",
    alpha_3: "AUS",
    country_code: "036",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:AU",
    ccTLD: ".au",
    phone_code: "+61"
  },
  {
    name: "Cocos (Keeling) Islands",
    name_official: "The Territory of Cocos (Keeling) Islands",
    alpha_2: "CC",
    alpha_3: "CCK",
    country_code: "166",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:CC",
    ccTLD: ".cc",
    phone_code: "+6189162"
  },
  {
    name: "Christmas Island",
    name_official: "The Territory of Christmas Island",
    alpha_2: "CX",
    alpha_3: "CXR",
    country_code: "162",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:CX",
    ccTLD: ".cx",
    phone_code: "+6189164"
  },
  {
    name: "Niue",
    name_official: "Niue",
    alpha_2: "NU",
    alpha_3: "NIU",
    country_code: "570",
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_number: "036",
    country_code_iso: "ISO 3166-2:NU",
    ccTLD: ".nu",
    phone_code: "+683"
  },
  {
    name: "India",
    name_official: "The Republic of India",
    alpha_2: "IN",
    alpha_3: "IND",
    country_code: "356",
    currency_name: "Indian Rupee",
    currency_code: "INR",
    currency_number: "360",
    country_code_iso: "ISO 3166-2:IN",
    ccTLD: ".in",
    phone_code: "+91"
  },
  {
    name: "Indonesia",
    name_official: "The Republic of Indonesia",
    alpha_2: "ID",
    alpha_3: "IDN",
    country_code: "360",
    currency_name: "Rupiah",
    currency_code: "IDR",
    currency_number: "364",
    country_code_iso: "ISO 3166-2:ID",
    ccTLD: ".id",
    phone_code: "+62"
  },
  {
    name: "Iran (Islamic Republic of)",
    name_official: "The Islamic Republic of Iran",
    alpha_2: "IR",
    alpha_3: "IRN",
    country_code: "364",
    currency_name: "Iranian Rial",
    currency_code: "IRR",
    currency_number: "368",
    country_code_iso: "ISO 3166-2:IR",
    ccTLD: ".ir",
    phone_code: "+98"
  },
  {
    name: "Ireland",
    name_official: "Ireland",
    alpha_2: "IE",
    alpha_3: "IRL",
    country_code: "372",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "376",
    country_code_iso: "ISO 3166-2:IE",
    ccTLD: ".ie",
    phone_code: "+353"
  },
  {
    name: "Ivory Coast",
    name_official: "The Republic of Côte d'Ivoire",
    alpha_2: "CI",
    alpha_3: "CIV",
    country_code: "384",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "388",
    country_code_iso: "ISO 3166-2:CI",
    ccTLD: ".ci",
    phone_code: "+225"
  },
  {
    name: "Jamaica",
    name_official: "Jamaica",
    alpha_2: "JM",
    alpha_3: "JAM",
    country_code: "388",
    currency_name: "Jamaican Dollar",
    currency_code: "JMD",
    currency_number: "392",
    country_code_iso: "ISO 3166-2:JM",
    ccTLD: ".jm",
    phone_code: "+1876"
  },
  {
    name: "Japan",
    name_official: "Japan",
    alpha_2: "JP",
    alpha_3: "JPN",
    country_code: "392",
    currency_name: "Yen",
    currency_code: "JPY",
    currency_number: "398",
    country_code_iso: "ISO 3166-2:JP",
    ccTLD: ".jp",
    phone_code: "+81"
  },
  {
    name: "Gambia",
    name_official: "The Republic of The Gambia",
    alpha_2: "GM",
    alpha_3: "GMB",
    country_code: "270",
    currency_name: "Dalasi",
    currency_code: "GMD",
    currency_number: "400",
    country_code_iso: "ISO 3166-2:GM",
    ccTLD: ".gm",
    phone_code: "+220"
  },
  {
    name: "Kazakhstan",
    name_official: "The Republic of Kazakhstan",
    alpha_2: "KZ",
    alpha_3: "KAZ",
    country_code: "398",
    currency_name: "Tenge",
    currency_code: "KZT",
    currency_number: "400",
    country_code_iso: "ISO 3166-2:KZ",
    ccTLD: ".kz",
    phone_code: "+76,+77"
  },
  {
    name: "Jordan",
    name_official: "The Hashemite Kingdom of Jordan",
    alpha_2: "JO",
    alpha_3: "JOR",
    country_code: "400",
    currency_name: "Jordanian Dinar",
    currency_code: "JOD",
    currency_number: "404",
    country_code_iso: "ISO 3166-2:JO",
    ccTLD: ".jo",
    phone_code: "+962"
  },
  {
    name: "Kenya",
    name_official: "The Republic of Kenya",
    alpha_2: "KE",
    alpha_3: "KEN",
    country_code: "404",
    currency_name: "Kenyan Shilling",
    currency_code: "KES",
    currency_number: "408",
    country_code_iso: "ISO 3166-2:KE",
    ccTLD: ".ke",
    phone_code: "+254"
  },
  {
    name: "Korea (the Democratic People's Republic of) ",
    name_official: "The Democratic People's Republic of Korea",
    alpha_2: "KP",
    alpha_3: "PRK",
    country_code: "408",
    currency_name: "North Korean Won",
    currency_code: "KPW",
    currency_number: "410",
    country_code_iso: "ISO 3166-2:KP",
    ccTLD: ".kp",
    phone_code: "+850"
  },
  {
    name: "Korea (the Republic of) ",
    name_official: "The Republic of Korea",
    alpha_2: "KR",
    alpha_3: "KOR",
    country_code: "410",
    currency_name: "Won",
    currency_code: "KRW",
    currency_number: "414",
    country_code_iso: "ISO 3166-2:KR",
    ccTLD: ".kr",
    phone_code: "+82"
  },
  {
    name: "Kuwait",
    name_official: "The State of Kuwait",
    alpha_2: "KW",
    alpha_3: "KWT",
    country_code: "414",
    currency_name: "Kuwaiti Dinar",
    currency_code: "KWD",
    currency_number: "417",
    country_code_iso: "ISO 3166-2:KW",
    ccTLD: ".kw",
    phone_code: "+965"
  },
  {
    name: "Kyrgyzstan",
    name_official: "The Kyrgyz Republic",
    alpha_2: "KG",
    alpha_3: "KGZ",
    country_code: "417",
    currency_name: "Som",
    currency_code: "KGS",
    currency_number: "418",
    country_code_iso: "ISO 3166-2:KG",
    ccTLD: ".kg",
    phone_code: "+996"
  },
  {
    name: "Laos",
    name_official: "The Lao People's Democratic Republic",
    alpha_2: "LA",
    alpha_3: "LAO",
    country_code: "418",
    currency_name: "Kip",
    currency_code: "LAK",
    currency_number: "422",
    country_code_iso: "ISO 3166-2:LA",
    ccTLD: ".la",
    phone_code: "+856"
  },
  {
    name: "Lebanon",
    name_official: "The Lebanese Republic",
    alpha_2: "LB",
    alpha_3: "LBN",
    country_code: "422",
    currency_name: "Lebanese Pound",
    currency_code: "LBP",
    currency_number: "426",
    country_code_iso: "ISO 3166-2:LB",
    ccTLD: ".lb",
    phone_code: "+961"
  },
  {
    name: "Latvia",
    name_official: "The Republic of Latvia",
    alpha_2: "LV",
    alpha_3: "LVA",
    country_code: "428",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "430",
    country_code_iso: "ISO 3166-2:LV",
    ccTLD: ".lv",
    phone_code: "+371"
  },
  {
    name: "Liberia",
    name_official: "The Republic of Liberia",
    alpha_2: "LR",
    alpha_3: "LBR",
    country_code: "430",
    currency_name: "Liberian Dollar",
    currency_code: "LRD",
    currency_number: "434",
    country_code_iso: "ISO 3166-2:LR",
    ccTLD: ".lr",
    phone_code: "+231"
  },
  {
    name: "Luxembourg",
    name_official: "The Grand Duchy of Luxembourg",
    alpha_2: "LU",
    alpha_3: "LUX",
    country_code: "442",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "446",
    country_code_iso: "ISO 3166-2:LU",
    ccTLD: ".lu",
    phone_code: "+352"
  },
  {
    name: "Madagascar",
    name_official: "The Republic of Madagascar",
    alpha_2: "MG",
    alpha_3: "MDG",
    country_code: "450",
    currency_name: "Malagasy Ariary",
    currency_code: "MGA",
    currency_number: "454",
    country_code_iso: "ISO 3166-2:MG",
    ccTLD: ".mg",
    phone_code: "+261"
  },
  {
    name: "Malawi",
    name_official: "The Republic of Malawi",
    alpha_2: "MW",
    alpha_3: "MWI",
    country_code: "454",
    currency_name: "Kwacha",
    currency_code: "MWK",
    currency_number: "458",
    country_code_iso: "ISO 3166-2:MW",
    ccTLD: ".mw",
    phone_code: "+265"
  },
  {
    name: "Malaysia",
    name_official: "Malaysia",
    alpha_2: "MY",
    alpha_3: "MYS",
    country_code: "458",
    currency_name: "Malaysian Ringgit",
    currency_code: "MYR",
    currency_number: "462",
    country_code_iso: "ISO 3166-2:MY",
    ccTLD: ".my",
    phone_code: "+60"
  },
  {
    name: "Bahrain",
    name_official: "The Kingdom of Bahrain",
    alpha_2: "BH",
    alpha_3: "BHR",
    country_code: "048",
    currency_name: "Bahraini Dinar",
    currency_code: "BHD",
    currency_number: "048",
    country_code_iso: "ISO 3166-2:BH",
    ccTLD: ".bh",
    phone_code: "+973"
  },
  {
    name: "Mauritania",
    name_official: "The Islamic Republic of Mauritania",
    alpha_2: "MR",
    alpha_3: "MRT",
    country_code: "478",
    currency_name: "Ouguiya",
    currency_code: "MRU",
    currency_number: "480",
    country_code_iso: "ISO 3166-2:MR",
    ccTLD: ".mr",
    phone_code: "+222"
  },
  {
    name: "Mauritius",
    name_official: "The Republic of Mauritius",
    alpha_2: "MU",
    alpha_3: "MUS",
    country_code: "480",
    currency_name: "Mauritius Rupee",
    currency_code: "MUR",
    currency_number: "484",
    country_code_iso: "ISO 3166-2:MU",
    ccTLD: ".mu",
    phone_code: "+230"
  },
  {
    name: "Monaco",
    name_official: "The Principality of Monaco",
    alpha_2: "MC",
    alpha_3: "MCO",
    country_code: "492",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "496",
    country_code_iso: "ISO 3166-2:MC",
    ccTLD: ".mc",
    phone_code: "+377"
  },
  {
    name: "Mongolia",
    name_official: "The State of Mongolia",
    alpha_2: "MN",
    alpha_3: "MNG",
    country_code: "496",
    currency_name: "Tugrik",
    currency_code: "MNT",
    currency_number: "498",
    country_code_iso: "ISO 3166-2:MN",
    ccTLD: ".mn",
    phone_code: "+976"
  },
  {
    name: "Bangladesh",
    name_official: "The People's Republic of Bangladesh",
    alpha_2: "BD",
    alpha_3: "BGD",
    country_code: "050",
    currency_name: "Taka",
    currency_code: "BDT",
    currency_number: "050",
    country_code_iso: "ISO 3166-2:BD",
    ccTLD: ".bd",
    phone_code: "+880"
  },
  {
    name: "Montserrat",
    name_official: "Montserrat",
    alpha_2: "MS",
    alpha_3: "MSR",
    country_code: "500",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "504",
    country_code_iso: "ISO 3166-2:MS",
    ccTLD: ".ms",
    phone_code: "+1664"
  },
  {
    name: "Sudan",
    name_official: "The Republic of the Sudan",
    alpha_2: "SD",
    alpha_3: "SDN",
    country_code: "729",
    currency_name: "Sudanese Pound",
    currency_code: "SDG",
    currency_number: "504",
    country_code_iso: "ISO 3166-2:SD",
    ccTLD: ".sd",
    phone_code: "+249"
  },
  {
    name: "Armenia",
    name_official: "The Republic of Armenia",
    alpha_2: "AM",
    alpha_3: "ARM",
    country_code: "051",
    currency_name: "Armenian Dram",
    currency_code: "AMD",
    currency_number: "051",
    country_code_iso: "ISO 3166-2:AM",
    ccTLD: ".am",
    phone_code: "+374"
  },
  {
    name: "Mozambique",
    name_official: "The Republic of Mozambique",
    alpha_2: "MZ",
    alpha_3: "MOZ",
    country_code: "508",
    currency_name: "Mozambique Metical",
    currency_code: "MZN",
    currency_number: "512",
    country_code_iso: "ISO 3166-2:MZ",
    ccTLD: ".mz",
    phone_code: "+258"
  },
  {
    name: "Oman",
    name_official: "The Sultanate of Oman",
    alpha_2: "OM",
    alpha_3: "OMN",
    country_code: "512",
    currency_name: "Rial Omani",
    currency_code: "OMR",
    currency_number: "516",
    country_code_iso: "ISO 3166-2:OM",
    ccTLD: ".om",
    phone_code: "+968"
  },
  {
    name: "Barbados",
    name_official: "Barbados",
    alpha_2: "BB",
    alpha_3: "BRB",
    country_code: "052",
    currency_name: "Barbados Dollar",
    currency_code: "BBD",
    currency_number: "052",
    country_code_iso: "ISO 3166-2:BB",
    ccTLD: ".bb",
    phone_code: "+1246"
  },
  {
    name: "Nauru",
    name_official: "The Republic of Nauru",
    alpha_2: "NR",
    alpha_3: "NRU",
    country_code: "520",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "524",
    country_code_iso: "ISO 3166-2:NR",
    ccTLD: ".nr",
    phone_code: "+674"
  },
  {
    name: "Aruba",
    name_official: "Aruba",
    alpha_2: "AW",
    alpha_3: "ABW",
    country_code: "533",
    currency_name: "Aruban Florin",
    currency_code: "AWG",
    currency_number: "532",
    country_code_iso: "ISO 3166-2:AW",
    ccTLD: ".aw",
    phone_code: "+297"
  },
  {
    name: "Netherlands",
    name_official: "The Kingdom of the Netherlands",
    alpha_2: "NL",
    alpha_3: "NLD",
    country_code: "528",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "532",
    country_code_iso: "ISO 3166-2:NL",
    ccTLD: ".nl",
    phone_code: "+31"
  },
  {
    name: "Curaçao",
    name_official: "The Country of Curaçao",
    alpha_2: "CW",
    alpha_3: "CUW",
    country_code: "531",
    currency_name: "Netherlands Antillean guilder",
    currency_code: "ANG",
    currency_number: "533",
    country_code_iso: "ISO 3166-2:CW",
    ccTLD: ".cw",
    phone_code: "+5999"
  },
  {
    name: "New Caledonia",
    name_official: "New Caledonia",
    alpha_2: "NC",
    alpha_3: "NCL",
    country_code: "540",
    currency_name: "CFP Franc",
    currency_code: "XPF",
    currency_number: "548",
    country_code_iso: "ISO 3166-2:NC",
    ccTLD: ".nc",
    phone_code: "+687"
  },
  {
    name: "Nigeria",
    name_official: "The Federal Republic of Nigeria",
    alpha_2: "NG",
    alpha_3: "NGA",
    country_code: "566",
    currency_name: "Naira",
    currency_code: "NGN",
    currency_number: "554",
    country_code_iso: "ISO 3166-2:NG",
    ccTLD: ".ng",
    phone_code: "+234"
  },
  {
    name: "Philippines",
    name_official: "The Republic of the Philippines",
    alpha_2: "PH",
    alpha_3: "PHL",
    country_code: "608",
    currency_name: "Philippine Peso",
    currency_code: "PHP",
    currency_number: "554",
    country_code_iso: "ISO 3166-2:PH",
    ccTLD: ".ph",
    phone_code: "+63"
  },
  {
    name: "New Zealand",
    name_official: "New Zealand",
    alpha_2: "NZ",
    alpha_3: "NZL",
    country_code: "554",
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_number: "554",
    country_code_iso: "ISO 3166-2:NZ",
    ccTLD: ".nz",
    phone_code: "+64"
  },
  {
    name: "Vanuatu",
    name_official: "The Republic of Vanuatu",
    alpha_2: "VU",
    alpha_3: "VUT",
    country_code: "548",
    currency_name: "Vatu",
    currency_code: "VUV",
    currency_number: "554",
    country_code_iso: "ISO 3166-2:VU",
    ccTLD: ".vu",
    phone_code: "+678"
  },
  {
    name: "Tokelau",
    name_official: "Tokelau",
    alpha_2: "TK",
    alpha_3: "TKL",
    country_code: "772",
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_number: "554",
    country_code_iso: "ISO 3166-2:TK",
    ccTLD: ".tk",
    phone_code: "+690"
  },
  {
    name: "Cook Islands",
    name_official: "The Cook Islands",
    alpha_2: "CK",
    alpha_3: "COK",
    country_code: "184",
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_number: "558",
    country_code_iso: "ISO 3166-2:CK",
    ccTLD: ".ck",
    phone_code: "+682"
  },
  {
    name: "Niger",
    name_official: "The Republic of the Niger",
    alpha_2: "NE",
    alpha_3: "NER",
    country_code: "562",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "566",
    country_code_iso: "ISO 3166-2:NE",
    ccTLD: ".ne",
    phone_code: "+227"
  },
  {
    name: "Suriname",
    name_official: "The Republic of Suriname",
    alpha_2: "SR",
    alpha_3: "SUR",
    country_code: "740",
    currency_name: "Surinam Dollar",
    currency_code: "SRD",
    currency_number: "578",
    country_code_iso: "ISO 3166-2:SR",
    ccTLD: ".sr",
    phone_code: "+597"
  },
  {
    name: "Norfolk Island",
    name_official: "The Territory of Norfolk Island",
    alpha_2: "NF",
    alpha_3: "NFK",
    country_code: "574",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "578",
    country_code_iso: "ISO 3166-2:NF",
    ccTLD: ".nf",
    phone_code: "+6723"
  },
  {
    name: "Palau",
    name_official: "The Republic of Palau",
    alpha_2: "PW",
    alpha_3: "PLW",
    country_code: "585",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "586",
    country_code_iso: "ISO 3166-2:PW",
    ccTLD: ".pw",
    phone_code: "+680"
  },
  {
    name: "Pakistan",
    name_official: "The Islamic Republic of Pakistan",
    alpha_2: "PK",
    alpha_3: "PAK",
    country_code: "586",
    currency_name: "Pakistan Rupee",
    currency_code: "PKR",
    currency_number: "590",
    country_code_iso: "ISO 3166-2:PK",
    ccTLD: ".pk",
    phone_code: "+92"
  },
  {
    name: "Panama",
    name_official: "The Republic of Panamá",
    alpha_2: "PA",
    alpha_3: "PAN",
    country_code: "591",
    currency_name: "Balboa",
    currency_code: "PAB",
    currency_number: "598",
    country_code_iso: "ISO 3166-2:PA",
    ccTLD: ".pa",
    phone_code: "+507"
  },
  {
    name: "Bermuda",
    name_official: "Bermuda",
    alpha_2: "BM",
    alpha_3: "BMU",
    country_code: "060",
    currency_name: "Bermudian Dollar",
    currency_code: "BMD",
    currency_number: "060",
    country_code_iso: "ISO 3166-2:BM",
    ccTLD: ".bm",
    phone_code: "+1441"
  },
  {
    name: "Papua New Guinea",
    name_official: "The Independent State of Papua New Guinea",
    alpha_2: "PG",
    alpha_3: "PNG",
    country_code: "598",
    currency_name: "Kina",
    currency_code: "PGK",
    currency_number: "600",
    country_code_iso: "ISO 3166-2:PG",
    ccTLD: ".pg",
    phone_code: "+675"
  },
  {
    name: "Paraguay",
    name_official: "The Republic of Paraguay",
    alpha_2: "PY",
    alpha_3: "PRY",
    country_code: "600",
    currency_name: "Guarani",
    currency_code: "PYG",
    currency_number: "604",
    country_code_iso: "ISO 3166-2:PY",
    ccTLD: ".py",
    phone_code: "+595"
  },
  {
    name: "Peru",
    name_official: "The Republic of Perú",
    alpha_2: "PE",
    alpha_3: "PER",
    country_code: "604",
    currency_name: "Nuevo Sol",
    currency_code: "PEN",
    currency_number: "608",
    country_code_iso: "ISO 3166-2:PE",
    ccTLD: ".pe",
    phone_code: "+51"
  },
  {
    name: "Puerto Rico",
    name_official: "The Commonwealth of Puerto Rico",
    alpha_2: "PR",
    alpha_3: "PRI",
    country_code: "630",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "634",
    country_code_iso: "ISO 3166-2:PR",
    ccTLD: ".pr",
    phone_code: "+1787,+1939"
  },
  {
    name: "Bhutan",
    name_official: "The Kingdom of Bhutan",
    alpha_2: "BT",
    alpha_3: "BTN",
    country_code: "064",
    currency_name: "Ngultrum",
    currency_code: "BTN",
    currency_number: "064",
    country_code_iso: "ISO 3166-2:BT",
    ccTLD: ".bt",
    phone_code: "+975"
  },
  {
    name: "Romania",
    name_official: "Romania",
    alpha_2: "RO",
    alpha_3: "ROU",
    country_code: "642",
    currency_name: "Romanian Leu",
    currency_code: "RON",
    currency_number: "643",
    country_code_iso: "ISO 3166-2:RO",
    ccTLD: ".ro",
    phone_code: "+40"
  },
  {
    name: "Russian Federation ",
    name_official: "The Russian Federation",
    alpha_2: "RU",
    alpha_3: "RUS",
    country_code: "643",
    currency_name: "Russian Ruble",
    currency_code: "RUB",
    currency_number: "646",
    country_code_iso: "ISO 3166-2:RU",
    ccTLD: ".ru",
    phone_code: "+7"
  },
  {
    name: "Saint Barthélemy",
    name_official: "The Collectivity of Saint-Barthélemy",
    alpha_2: "BL",
    alpha_3: "BLM",
    country_code: "652",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "654",
    country_code_iso: "ISO 3166-2:BL",
    ccTLD: ".bl",
    phone_code: "+590"
  },
  {
    name: "Bolivia (Plurinational State of)",
    name_official: "The Plurinational State of Bolivia",
    alpha_2: "BO",
    alpha_3: "BOL",
    country_code: "068",
    currency_name: "Boliviano",
    currency_code: "BOB",
    currency_number: "068",
    country_code_iso: "ISO 3166-2:BO",
    ccTLD: ".bo",
    phone_code: "+591"
  },
  {
    name: "Sao Tome and Principe",
    name_official: "The Democratic Republic of São Tomé and Príncipe",
    alpha_2: "ST",
    alpha_3: "STP",
    country_code: "678",
    currency_name: "Dobra",
    currency_code: "STN",
    currency_number: "682",
    country_code_iso: "ISO 3166-2:ST",
    ccTLD: ".st",
    phone_code: "+239"
  },
  {
    name: "Serbia",
    name_official: "The Republic of Serbia",
    alpha_2: "RS",
    alpha_3: "SRB",
    country_code: "688",
    currency_name: "Serbian Dinar",
    currency_code: "RSD",
    currency_number: "690",
    country_code_iso: "ISO 3166-2:RS",
    ccTLD: ".rs",
    phone_code: "+381"
  },
  {
    name: "Seychelles",
    name_official: "The Republic of Seychelles",
    alpha_2: "SC",
    alpha_3: "SYC",
    country_code: "690",
    currency_name: "Seychelles Rupee",
    currency_code: "SCR",
    currency_number: "694",
    country_code_iso: "ISO 3166-2:SC",
    ccTLD: ".sc",
    phone_code: "+248"
  },
  {
    name: "Sierra Leone",
    name_official: "The Republic of Sierra Leone",
    alpha_2: "SL",
    alpha_3: "SLE",
    country_code: "694",
    currency_name: "Leone",
    currency_code: "SLL",
    currency_number: "702",
    country_code_iso: "ISO 3166-2:SL",
    ccTLD: ".sl",
    phone_code: "+232"
  },
  {
    name: "Slovakia",
    name_official: "The Slovak Republic",
    alpha_2: "SK",
    alpha_3: "SVK",
    country_code: "703",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "704",
    country_code_iso: "ISO 3166-2:SK",
    ccTLD: ".sk",
    phone_code: "+421"
  },
  {
    name: "Slovenia",
    name_official: "The Republic of Slovenia",
    alpha_2: "SI",
    alpha_3: "SVN",
    country_code: "705",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "706",
    country_code_iso: "ISO 3166-2:SI",
    ccTLD: ".si",
    phone_code: "+386"
  },
  {
    name: "Somalia",
    name_official: "The Federal Republic of Somalia",
    alpha_2: "SO",
    alpha_3: "SOM",
    country_code: "706",
    currency_name: "Somali Shilling",
    currency_code: "SOS",
    currency_number: "710",
    country_code_iso: "ISO 3166-2:SO",
    ccTLD: ".so",
    phone_code: "+252"
  },
  {
    name: "Botswana",
    name_official: "The Republic of Botswana",
    alpha_2: "BW",
    alpha_3: "BWA",
    country_code: "072",
    currency_name: "Pula",
    currency_code: "BWP",
    currency_number: "072",
    country_code_iso: "ISO 3166-2:BW",
    ccTLD: ".bw",
    phone_code: "+267"
  },
  {
    name: "Spain",
    name_official: "The Kingdom of Spain",
    alpha_2: "ES",
    alpha_3: "ESP",
    country_code: "724",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "728",
    country_code_iso: "ISO 3166-2:ES",
    ccTLD: ".es",
    phone_code: "+34"
  },
  {
    name: "Svalbard",
    name_official: "Svalbard and Jan Mayen",
    alpha_2: "SJ",
    alpha_3: "SJM",
    country_code: "744",
    currency_name: "Norwegian Krone",
    currency_code: "NOK",
    currency_number: "748",
    country_code_iso: "ISO 3166-2:SJ",
    ccTLD: "",
    phone_code: "+4779"
  },
  {
    name: "Eswatini ",
    name_official: "The Kingdom of Eswatini",
    alpha_2: "SZ",
    alpha_3: "SWZ",
    country_code: "748",
    currency_name: "Lilangeni",
    currency_code: "SZL",
    currency_number: "752",
    country_code_iso: "ISO 3166-2:SZ",
    ccTLD: ".sz",
    phone_code: "+268"
  },
  {
    name: "Switzerland",
    name_official: "The Swiss Confederation",
    alpha_2: "CH",
    alpha_3: "CHE",
    country_code: "756",
    currency_name: "Swiss Franc",
    currency_code: "CHF",
    currency_number: "756",
    country_code_iso: "ISO 3166-2:CH",
    ccTLD: ".ch",
    phone_code: "+41"
  },
  {
    name: "Liechtenstein",
    name_official: "The Principality of Liechtenstein",
    alpha_2: "LI",
    alpha_3: "LIE",
    country_code: "438",
    currency_name: "Swiss Franc",
    currency_code: "CHF",
    currency_number: "756",
    country_code_iso: "ISO 3166-2:LI",
    ccTLD: ".li",
    phone_code: "+423"
  },
  {
    name: "Sweden",
    name_official: "The Kingdom of Sweden",
    alpha_2: "SE",
    alpha_3: "SWE",
    country_code: "752",
    currency_name: "Swedish Krona",
    currency_code: "SEK",
    currency_number: "756",
    country_code_iso: "ISO 3166-2:SE",
    ccTLD: ".se",
    phone_code: "+46"
  },
  {
    name: "Syria",
    name_official: "The Syrian Arab Republic",
    alpha_2: "SY",
    alpha_3: "SYR",
    country_code: "760",
    currency_name: "Syrian Pound",
    currency_code: "SYP",
    currency_number: "760",
    country_code_iso: "ISO 3166-2:SY",
    ccTLD: ".sy",
    phone_code: "+963"
  },
  {
    name: "Thailand",
    name_official: "The Kingdom of Thailand",
    alpha_2: "TH",
    alpha_3: "THA",
    country_code: "764",
    currency_name: "Baht",
    currency_code: "THB",
    currency_number: "764",
    country_code_iso: "ISO 3166-2:TH",
    ccTLD: ".th",
    phone_code: "+66"
  },
  {
    name: "Tonga",
    name_official: "The Kingdom of Tonga",
    alpha_2: "TO",
    alpha_3: "TON",
    country_code: "776",
    currency_name: "Pa’anga",
    currency_code: "TOP",
    currency_number: "776",
    country_code_iso: "ISO 3166-2:TO",
    ccTLD: ".to",
    phone_code: "+676"
  },
  {
    name: "Trinidad and Tobago",
    name_official: "The Republic of Trinidad and Tobago",
    alpha_2: "TT",
    alpha_3: "TTO",
    country_code: "780",
    currency_name: "Trinidad and Tobago Dollar",
    currency_code: "TTD",
    currency_number: "780",
    country_code_iso: "ISO 3166-2:TT",
    ccTLD: ".tt",
    phone_code: "+1868"
  },
  {
    name: "United Arab Emirates",
    name_official: "The United Arab Emirates",
    alpha_2: "AE",
    alpha_3: "ARE",
    country_code: "784",
    currency_name: "UAE Dirham",
    currency_code: "AED",
    currency_number: "784",
    country_code_iso: "ISO 3166-2:AE",
    ccTLD: ".ae",
    phone_code: "+971"
  },
  {
    name: "Tunisia",
    name_official: "The Republic of Tunisia",
    alpha_2: "TN",
    alpha_3: "TUN",
    country_code: "788",
    currency_name: "Tunisian Dinar",
    currency_code: "TND",
    currency_number: "788",
    country_code_iso: "ISO 3166-2:TN",
    ccTLD: ".tn",
    phone_code: "+216"
  },
  {
    name: "Uganda",
    name_official: "The Republic of Uganda",
    alpha_2: "UG",
    alpha_3: "UGA",
    country_code: "800",
    currency_name: "Uganda Shilling",
    currency_code: "UGX",
    currency_number: "800",
    country_code_iso: "ISO 3166-2:UG",
    ccTLD: ".ug",
    phone_code: "+256"
  },
  {
    name: "North Macedonia ",
    name_official: "Republic of North Macedonia",
    alpha_2: "MK",
    alpha_3: "MKD",
    country_code: "807",
    currency_name: "Denar",
    currency_code: "MKD",
    currency_number: "807",
    country_code_iso: "ISO 3166-2:MK",
    ccTLD: ".mk",
    phone_code: "+389"
  },
  {
    name: "Egypt",
    name_official: "The Arab Republic of Egypt",
    alpha_2: "EG",
    alpha_3: "EGY",
    country_code: "818",
    currency_name: "Egyptian Pound",
    currency_code: "EGP",
    currency_number: "818",
    country_code_iso: "ISO 3166-2:EG",
    ccTLD: ".eg",
    phone_code: "+20"
  },
  {
    name: "United Kingdom",
    name_official: "The United Kingdom of Great Britain and Northern Ireland",
    alpha_2: "GB",
    alpha_3: "GBR",
    country_code: "826",
    currency_name: "Pound Sterling",
    currency_code: "GBP",
    currency_number: "826",
    country_code_iso: "ISO 3166-2:GB",
    ccTLD: ".uk ",
    phone_code: "+44"
  },
  {
    name: "Tanzania",
    name_official: "The United Republic of Tanzania",
    alpha_2: "TZ",
    alpha_3: "TZA",
    country_code: "834",
    currency_name: "Tanzanian Shilling",
    currency_code: "TZS",
    currency_number: "834",
    country_code_iso: "ISO 3166-2:TZ",
    ccTLD: ".tz",
    phone_code: "+255"
  },
  {
    name: "Belize",
    name_official: "Belize",
    alpha_2: "BZ",
    alpha_3: "BLZ",
    country_code: "084",
    currency_name: "Belize Dollar",
    currency_code: "BZD",
    currency_number: "084",
    country_code_iso: "ISO 3166-2:BZ",
    ccTLD: ".bz",
    phone_code: "+501"
  },
  {
    name: "United States",
    name_official: "The United States of America",
    alpha_2: "US",
    alpha_3: "USA",
    country_code: "840",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:US",
    ccTLD: ".us",
    phone_code: "+1"
  },
  {
    name: "Virgin Islands (British) ",
    name_official: "The Virgin Islands",
    alpha_2: "VG",
    alpha_3: "VGB",
    country_code: "092",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:VG",
    ccTLD: ".vg",
    phone_code: "+1284"
  },
  {
    name: "Virgin Islands (U.S.) ",
    name_official: "The Virgin Islands of the United States",
    alpha_2: "VI",
    alpha_3: "VIR",
    country_code: "850",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:VI",
    ccTLD: ".vi",
    phone_code: "+1340"
  },
  {
    name: "Turks and Caicos Islands",
    name_official: "The Turks and Caicos Islands",
    alpha_2: "TC",
    alpha_3: "TCA",
    country_code: "796",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:TC",
    ccTLD: ".tc",
    phone_code: "+1649"
  },
  {
    name: "Northern Mariana Islands",
    name_official: "The Commonwealth of the Northern Mariana Islands",
    alpha_2: "MP",
    alpha_3: "MNP",
    country_code: "580",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:MP",
    ccTLD: ".mp",
    phone_code: "+1670"
  },
  {
    name: "American Samoa",
    name_official: "The Territory of American Samoa",
    alpha_2: "AS",
    alpha_3: "ASM",
    country_code: "016",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:AS",
    ccTLD: ".as",
    phone_code: "+1684"
  },
  {
    name: "Sint Maarten (Netherlands)",
    name_official: "Sint Maarten",
    alpha_2: "SX",
    alpha_3: "SXM",
    country_code: "534",
    currency_name: "Netherlands Antillean guilder",
    currency_code: "ANG",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:SX",
    ccTLD: ".sx",
    phone_code: "+1721"
  },
  {
    name: "Dominican Republic",
    name_official: "The Dominican Republic",
    alpha_2: "DO",
    alpha_3: "DOM",
    country_code: "214",
    currency_name: "Dominican Peso",
    currency_code: "DOP",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:DO",
    ccTLD: ".do",
    phone_code: "+1809,+1829,+1849"
  },
  {
    name: "Guinea-Bissau",
    name_official: "The Republic of Guinea-Bissau",
    alpha_2: "GW",
    alpha_3: "GNB",
    country_code: "624",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:GW",
    ccTLD: ".gw",
    phone_code: "+245"
  },
  {
    name: "British Indian Ocean Territory",
    name_official: "The British Indian Ocean Territory",
    alpha_2: "IO",
    alpha_3: "IOT",
    country_code: "086",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:IO",
    ccTLD: ".io",
    phone_code: "+246"
  },
  {
    name: "Norway",
    name_official: "The Kingdom of Norway",
    alpha_2: "NO",
    alpha_3: "NOR",
    country_code: "578",
    currency_name: "Norwegian Krone",
    currency_code: "NOK",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:NO",
    ccTLD: ".no",
    phone_code: "+47"
  },
  {
    name: "Guadeloupe",
    name_official: "Guadeloupe",
    alpha_2: "GP",
    alpha_3: "GLP",
    country_code: "312",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:GP",
    ccTLD: ".gp",
    phone_code: "+590"
  },
  {
    name: "Timor-Leste ",
    name_official: "The Democratic Republic of Timor-Leste",
    alpha_2: "TL",
    alpha_3: "TLS",
    country_code: "626",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:TL",
    ccTLD: ".tl",
    phone_code: "+670"
  },
  {
    name: "Micronesia (Federated States of)",
    name_official: "The Federated States of Micronesia",
    alpha_2: "FM",
    alpha_3: "FSM",
    country_code: "583",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:FM",
    ccTLD: ".fm",
    phone_code: "+691"
  },
  {
    name: "Marshall Islands",
    name_official: "The Republic of the Marshall Islands",
    alpha_2: "MH",
    alpha_3: "MHL",
    country_code: "584",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "840",
    country_code_iso: "ISO 3166-2:MH",
    ccTLD: ".mh",
    phone_code: "+692"
  },
  {
    name: "Uruguay",
    name_official: "The Oriental Republic of Uruguay",
    alpha_2: "UY",
    alpha_3: "URY",
    country_code: "858",
    currency_name: "Peso Uruguayo",
    currency_code: "UYU",
    currency_number: "858",
    country_code_iso: "ISO 3166-2:UY",
    ccTLD: ".uy",
    phone_code: "+598"
  },
  {
    name: "Uzbekistan",
    name_official: "The Republic of Uzbekistan",
    alpha_2: "UZ",
    alpha_3: "UZB",
    country_code: "860",
    currency_name: "Uzbekistan Sum",
    currency_code: "UZS",
    currency_number: "860",
    country_code_iso: "ISO 3166-2:UZ",
    ccTLD: ".uz",
    phone_code: "+998"
  },
  {
    name: "Samoa",
    name_official: "The Independent State of Samoa",
    alpha_2: "WS",
    alpha_3: "WSM",
    country_code: "882",
    currency_name: "Tala",
    currency_code: "WST",
    currency_number: "882",
    country_code_iso: "ISO 3166-2:WS",
    ccTLD: ".ws",
    phone_code: "+685"
  },
  {
    name: "Yemen",
    name_official: "The Republic of Yemen",
    alpha_2: "YE",
    alpha_3: "YEM",
    country_code: "887",
    currency_name: "Yemeni Rial",
    currency_code: "YER",
    currency_number: "886",
    country_code_iso: "ISO 3166-2:YE",
    ccTLD: ".ye",
    phone_code: "+967"
  },
  {
    name: "Zambia",
    name_official: "The Republic of Zambia",
    alpha_2: "ZM",
    alpha_3: "ZMB",
    country_code: "894",
    currency_name: "The Zambian Kwacha",
    currency_code: "ZMK",
    currency_number: "894",
    country_code_iso: "ISO 3166-2:ZM",
    ccTLD: ".zm",
    phone_code: "+260"
  },
  {
    name: "Solomon Islands",
    name_official: "The Solomon Islands",
    alpha_2: "SB",
    alpha_3: "SLB",
    country_code: "090",
    currency_name: "Solomon Islands Dollar",
    currency_code: "SBD",
    currency_number: "090",
    country_code_iso: "ISO 3166-2:SB",
    ccTLD: ".sb",
    phone_code: "+677"
  },
  {
    name: "Taiwan",
    name_official: " Taiwan (Province of China) ",
    alpha_2: "TW",
    alpha_3: "TWN",
    country_code: "158",
    currency_name: "New Taiwan Dollar",
    currency_code: "TWD",
    currency_number: "901",
    country_code_iso: "ISO 3166-2:TW",
    ccTLD: ".tw",
    phone_code: "+886"
  },
  {
    name: "Martinique",
    name_official: "Martinique",
    alpha_2: "MQ",
    alpha_3: "MTQ",
    country_code: "474",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "929",
    country_code_iso: "ISO 3166-2:MQ",
    ccTLD: ".mq",
    phone_code: "+596"
  },
  {
    name: "San Marino",
    name_official: "The Republic of San Marino",
    alpha_2: "SM",
    alpha_3: "SMR",
    country_code: "674",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "930",
    country_code_iso: "ISO 3166-2:SM",
    ccTLD: ".sm",
    phone_code: "+378"
  },
  {
    name: "Turkmenistan",
    name_official: "Turkmenistan",
    alpha_2: "TM",
    alpha_3: "TKM",
    country_code: "795",
    currency_name: "Turkmenistan New Manat",
    currency_code: "TMT",
    currency_number: "934",
    country_code_iso: "ISO 3166-2:TM",
    ccTLD: ".tm",
    phone_code: "+993"
  },
  {
    name: "Germany",
    name_official: "The Federal Republic of Germany",
    alpha_2: "DE",
    alpha_3: "DEU",
    country_code: "276",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "936",
    country_code_iso: "ISO 3166-2:DE",
    ccTLD: ".de",
    phone_code: "+49"
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    name_official: "The Bolivarian Republic of Venezuela",
    alpha_2: "VE",
    alpha_3: "VEN",
    country_code: "862",
    currency_name: "Venezuelan bolívar",
    currency_code: "VEF",
    currency_number: "937",
    country_code_iso: "ISO 3166-2:VE",
    ccTLD: ".ve",
    phone_code: "+58"
  },
  {
    name: "South Sudan",
    name_official: "The Republic of South Sudan",
    alpha_2: "SS",
    alpha_3: "SSD",
    country_code: "728",
    currency_name: "South Sudanese Pound",
    currency_code: "SSP",
    currency_number: "938",
    country_code_iso: "ISO 3166-2:SS",
    ccTLD: ".ss",
    phone_code: "+211"
  },
  {
    name: "Senegal",
    name_official: "The Republic of Senegal",
    alpha_2: "SN",
    alpha_3: "SEN",
    country_code: "686",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "941",
    country_code_iso: "ISO 3166-2:SN",
    ccTLD: ".sn",
    phone_code: "+221"
  },
  {
    name: "Morocco",
    name_official: "The Kingdom of Morocco",
    alpha_2: "MA",
    alpha_3: "MAR",
    country_code: "504",
    currency_name: "Moroccan Dirham",
    currency_code: "MAD",
    currency_number: "943",
    country_code_iso: "ISO 3166-2:MA",
    ccTLD: ".ma",
    phone_code: "+212"
  },
  {
    name: "Azerbaijan",
    name_official: "The Republic of Azerbaijan",
    alpha_2: "AZ",
    alpha_3: "AZE",
    country_code: "031",
    currency_name: "Azerbaijanian Manat",
    currency_code: "AZN",
    currency_number: "944",
    country_code_iso: "ISO 3166-2:AZ",
    ccTLD: ".az",
    phone_code: "+994"
  },
  {
    name: "Réunion",
    name_official: "Réunion",
    alpha_2: "RE",
    alpha_3: "REU",
    country_code: "638",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "946",
    country_code_iso: "ISO 3166-2:RE",
    ccTLD: ".re",
    phone_code: "+262"
  },
  {
    name: "Turkey",
    name_official: "The Republic of Turkey",
    alpha_2: "TR",
    alpha_3: "TUR",
    country_code: "792",
    currency_name: "Turkish Lira",
    currency_code: "TRY",
    currency_number: "949",
    country_code_iso: "ISO 3166-2:TR",
    ccTLD: ".tr",
    phone_code: "+90"
  },
  {
    name: "Chad",
    name_official: "The Republic of Chad",
    alpha_2: "TD",
    alpha_3: "TCD",
    country_code: "148",
    currency_name: "CFA Franc BEAC",
    currency_code: "XAF",
    currency_number: "950",
    country_code_iso: "ISO 3166-2:TD",
    ccTLD: ".td",
    phone_code: "+235"
  },
  {
    name: "Central African Republic",
    name_official: "The Central African Republic",
    alpha_2: "CF",
    alpha_3: "CAF",
    country_code: "140",
    currency_name: "CFA Franc BEAC",
    currency_code: "XAF",
    currency_number: "950",
    country_code_iso: "ISO 3166-2:CF",
    ccTLD: ".cf",
    phone_code: "+236"
  },
  {
    name: "Cameroon",
    name_official: "The Republic of Cameroon",
    alpha_2: "CM",
    alpha_3: "CMR",
    country_code: "120",
    currency_name: "CFA Franc BEAC",
    currency_code: "XAF",
    currency_number: "950",
    country_code_iso: "ISO 3166-2:CM",
    ccTLD: ".cm",
    phone_code: "+237"
  },
  {
    name: "Congo ",
    name_official: "The Republic of the Congo",
    alpha_2: "CG",
    alpha_3: "COG",
    country_code: "178",
    currency_name: "CFA Franc BEAC",
    currency_code: "XAF",
    currency_number: "950",
    country_code_iso: "ISO 3166-2:CG",
    ccTLD: ".cg",
    phone_code: "+242"
  },
  {
    name: "Djibouti",
    name_official: "The Republic of Djibouti",
    alpha_2: "DJ",
    alpha_3: "DJI",
    country_code: "262",
    currency_name: "Djibouti Franc",
    currency_code: "DJF",
    currency_number: "950",
    country_code_iso: "ISO 3166-2:DJ",
    ccTLD: ".dj",
    phone_code: "+253"
  },
  {
    name: "El Salvador",
    name_official: "The Republic of El Salvador",
    alpha_2: "SV",
    alpha_3: "SLV",
    country_code: "222",
    currency_name: "El Salvador Colon",
    currency_code: "SVC",
    currency_number: "950",
    country_code_iso: "ISO 3166-2:SV",
    ccTLD: ".sv",
    phone_code: "+503"
  },
  {
    name: "Anguilla",
    name_official: "Anguilla",
    alpha_2: "AI",
    alpha_3: "AIA",
    country_code: "660",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:AI",
    ccTLD: ".ai",
    phone_code: "+1264"
  },
  {
    name: "Antigua and Barbuda",
    name_official: "Antigua and Barbuda",
    alpha_2: "AG",
    alpha_3: "ATG",
    country_code: "028",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:AG",
    ccTLD: ".ag",
    phone_code: "+1268"
  },
  {
    name: "Saint Kitts and Nevis",
    name_official: "Saint Kitts and Nevis",
    alpha_2: "KN",
    alpha_3: "KNA",
    country_code: "659",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:KN",
    ccTLD: ".kn",
    phone_code: "+1869"
  },
  {
    name: "Saint Helena",
    name_official: "Saint Helena, Ascension and Tristan da Cunha",
    alpha_2: "SH",
    alpha_3: "SHN",
    country_code: "654",
    currency_name: "Saint Helena Pound",
    currency_code: "SHP",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:SH",
    ccTLD: ".sh",
    phone_code: "+290"
  },
  {
    name: "Greenland",
    name_official: "Kalaallit Nunaat",
    alpha_2: "GL",
    alpha_3: "GRL",
    country_code: "304",
    currency_name: "Danish Krone",
    currency_code: "DKK",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:GL",
    ccTLD: ".gl",
    phone_code: "+299"
  },
  {
    name: "Montenegro",
    name_official: "Montenegro",
    alpha_2: "ME",
    alpha_3: "MNE",
    country_code: "499",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:ME",
    ccTLD: ".me",
    phone_code: "+382"
  },
  {
    name: "Denmark",
    name_official: "The Kingdom of Denmark",
    alpha_2: "DK",
    alpha_3: "DNK",
    country_code: "208",
    currency_name: "Danish Krone",
    currency_code: "DKK",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:DK",
    ccTLD: ".dk",
    phone_code: "+45"
  },
  {
    name: "Saint Pierre and Miquelon",
    name_official: "The Overseas Collectivity of Saint-Pierre and Miquelon",
    alpha_2: "PM",
    alpha_3: "SPM",
    country_code: "666",
    currency_name: "Euro		",
    currency_code: "EUR",
    currency_number: "951",
    country_code_iso: "ISO 3166-2:PM",
    ccTLD: ".pm",
    phone_code: "+508"
  },
  {
    name: "Burkina Faso",
    name_official: "Burkina Faso",
    alpha_2: "BF",
    alpha_3: "BFA",
    country_code: "854",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:BF",
    ccTLD: ".bf",
    phone_code: "+226"
  },
  {
    name: "Togo",
    name_official: "The Togolese Republic",
    alpha_2: "TG",
    alpha_3: "TGO",
    country_code: "768",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:TG",
    ccTLD: ".tg",
    phone_code: "+228"
  },
  {
    name: "Portugal",
    name_official: "The Portuguese Republic",
    alpha_2: "PT",
    alpha_3: "PRT",
    country_code: "620",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:PT",
    ccTLD: ".pt",
    phone_code: "+351"
  },
  {
    name: "Italy",
    name_official: "The Italian Republic",
    alpha_2: "IT",
    alpha_3: "ITA",
    country_code: "380",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:IT",
    ccTLD: ".it",
    phone_code: "+39"
  },
  {
    name: "Czech Republic",
    name_official: "The Czech Republic",
    alpha_2: "CZ",
    alpha_3: "CZE",
    country_code: "203",
    currency_name: "Czech Koruna",
    currency_code: "CZK",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:CZ",
    ccTLD: ".cz",
    phone_code: "+420"
  },
  {
    name: "Nicaragua",
    name_official: "The Republic of Nicaragua",
    alpha_2: "NI",
    alpha_3: "NIC",
    country_code: "558",
    currency_name: "Cordoba Oro",
    currency_code: "NIO",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:NI",
    ccTLD: ".ni",
    phone_code: "+505"
  },
  {
    name: "Maldives",
    name_official: "The Republic of Maldives",
    alpha_2: "MV",
    alpha_3: "MDV",
    country_code: "462",
    currency_name: "Rufiyaa",
    currency_code: "MVR",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:MV",
    ccTLD: ".mv",
    phone_code: "+960"
  },
  {
    name: "Saudi Arabia",
    name_official: "The Kingdom of Saudi Arabia",
    alpha_2: "SA",
    alpha_3: "SAU",
    country_code: "682",
    currency_name: "Saudi Riyal",
    currency_code: "SAR",
    currency_number: "952",
    country_code_iso: "ISO 3166-2:SA",
    ccTLD: ".sa",
    phone_code: "+966"
  },
  {
    name: "French Guiana",
    name_official: "Guyane",
    alpha_2: "GF",
    alpha_3: "GUF",
    country_code: "254",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "953",
    country_code_iso: "ISO 3166-2:GF",
    ccTLD: ".gf",
    phone_code: "+594"
  },
  {
    name: "Bonaire",
    name_official: "Bonaire, Sint Eustatius and Saba",
    alpha_2: "BQ",
    alpha_3: "BES",
    country_code: "535",
    currency_name: "US Dollar",
    currency_code: "USD",
    currency_number: "953",
    country_code_iso: "ISO 3166-2:BQ",
    ccTLD: ".nl ",
    phone_code: "+5997"
  },
  {
    name: "Wallis and Futuna",
    name_official: "The Territory of the Wallis and Futuna Islands",
    alpha_2: "WF",
    alpha_3: "WLF",
    country_code: "876",
    currency_name: "The CFP franc",
    currency_code: "XPF",
    currency_number: "953",
    country_code_iso: "ISO 3166-2:WF",
    ccTLD: ".wf",
    phone_code: "+681"
  },
  {
    name: "Brunei Darussalam ",
    name_official: "The Nation of Brunei, the Abode of Peace",
    alpha_2: "BN",
    alpha_3: "BRN",
    country_code: "096",
    currency_name: "Brunei Dollar",
    currency_code: "BND",
    currency_number: "096",
    country_code_iso: "ISO 3166-2:BN",
    ccTLD: ".bn",
    phone_code: "+673"
  },
  {
    name: "Western Sahara ",
    name_official: "The Sahrawi Arab Democratic Republic",
    alpha_2: "EH",
    alpha_3: "ESH",
    country_code: "732",
    currency_name: "Moroccan Dirham",
    currency_code: "MAD",
    currency_number: "968",
    country_code_iso: "ISO 3166-2:EH",
    ccTLD: ".eh",
    phone_code: "+212"
  },
  {
    name: "Macao ",
    name_official: "Macao Special Administrative Region of China",
    alpha_2: "MO",
    alpha_3: "MAC",
    country_code: "446",
    currency_name: "Pataca",
    currency_code: "MOP",
    currency_number: "969",
    country_code_iso: "ISO 3166-2:MO",
    ccTLD: ".mo",
    phone_code: "+853"
  },
  {
    name: "Afghanistan",
    name_official: "The Islamic Republic of Afghanistan",
    alpha_2: "AF",
    alpha_3: "AFG",
    country_code: "004",
    currency_name: "Afghani",
    currency_code: "AFN",
    currency_number: "971",
    country_code_iso: "ISO 3166-2:AF",
    ccTLD: ".af",
    phone_code: "+93"
  },
  {
    name: "Tajikistan",
    name_official: "The Republic of Tajikistan",
    alpha_2: "TJ",
    alpha_3: "TJK",
    country_code: "762",
    currency_name: "Somoni",
    currency_code: "TJS",
    currency_number: "972",
    country_code_iso: "ISO 3166-2:TJ",
    ccTLD: ".tj",
    phone_code: "+992"
  },
  {
    name: "Angola",
    name_official: "The Republic of Angola",
    alpha_2: "AO",
    alpha_3: "AGO",
    country_code: "024",
    currency_name: "Kwanza",
    currency_code: "AOA",
    currency_number: "973",
    country_code_iso: "ISO 3166-2:AO",
    ccTLD: ".ao",
    phone_code: "+244"
  },
  {
    name: "Belarus",
    name_official: "The Republic of Belarus",
    alpha_2: "BY",
    alpha_3: "BLR",
    country_code: "112",
    currency_name: "Belarussian Ruble",
    currency_code: "BYR",
    currency_number: "974",
    country_code_iso: "ISO 3166-2:BY",
    ccTLD: ".by",
    phone_code: "+375"
  },
  {
    name: "Bulgaria",
    name_official: "The Republic of Bulgaria",
    alpha_2: "BG",
    alpha_3: "BGR",
    country_code: "100",
    currency_name: "Bulgarian Lev",
    currency_code: "BGN",
    currency_number: "975",
    country_code_iso: "ISO 3166-2:BG",
    ccTLD: ".bg",
    phone_code: "+359"
  },
  {
    name: "Congo (the Democratic Republic of the)",
    name_official: "The Democratic Republic of the Congo",
    alpha_2: "CD",
    alpha_3: "COD",
    country_code: "180",
    currency_name: "Congolese Franc",
    currency_code: "CDF",
    currency_number: "976",
    country_code_iso: "ISO 3166-2:CD",
    ccTLD: ".cd",
    phone_code: "+243"
  },
  {
    name: "Bosnia and Herzegovina",
    name_official: "Bosnia and Herzegovina",
    alpha_2: "BA",
    alpha_3: "BIH",
    country_code: "070",
    currency_name: "Convertible Mark",
    currency_code: "BAM",
    currency_number: "977",
    country_code_iso: "ISO 3166-2:BA",
    ccTLD: ".ba",
    phone_code: "+387"
  },
  {
    name: "Grenada",
    name_official: "Grenada",
    alpha_2: "GD",
    alpha_3: "GRD",
    country_code: "308",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:GD",
    ccTLD: ".gd",
    phone_code: "+1473"
  },
  {
    name: "Saint Lucia",
    name_official: "Saint Lucia",
    alpha_2: "LC",
    alpha_3: "LCA",
    country_code: "662",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:LC",
    ccTLD: ".lc",
    phone_code: "+1758"
  },
  {
    name: "Saint Vincent and the Grenadines",
    name_official: "Saint Vincent and the Grenadines",
    alpha_2: "VC",
    alpha_3: "VCT",
    country_code: "670",
    currency_name: "East Caribbean Dollar",
    currency_code: "XCD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:VC",
    ccTLD: ".vc",
    phone_code: "+1784"
  },
  {
    name: "Libya",
    name_official: "The State of Libya",
    alpha_2: "LY",
    alpha_3: "LBY",
    country_code: "434",
    currency_name: "Libyan Dinar",
    currency_code: "LYD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:LY",
    ccTLD: ".ly",
    phone_code: "+218"
  },
  {
    name: "Mali",
    name_official: "The Republic of Mali",
    alpha_2: "ML",
    alpha_3: "MLI",
    country_code: "466",
    currency_name: "CFA Franc BCEAO",
    currency_code: "XOF",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:ML",
    ccTLD: ".ml",
    phone_code: "+223"
  },
  {
    name: "Rwanda",
    name_official: "The Republic of Rwanda",
    alpha_2: "RW",
    alpha_3: "RWA",
    country_code: "646",
    currency_name: "Rwanda Franc",
    currency_code: "RWF",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:RW",
    ccTLD: ".rw",
    phone_code: "+250"
  },
  {
    name: "Zimbabwe",
    name_official: "The Republic of Zimbabwe",
    alpha_2: "ZW",
    alpha_3: "ZWE",
    country_code: "716",
    currency_name: "The Zimbabwean dollar",
    currency_code: "ZWL",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:ZW",
    ccTLD: ".zw",
    phone_code: "+25524"
  },
  {
    name: "Mayotte",
    name_official: "The Department of Mayotte",
    alpha_2: "YT",
    alpha_3: "MYT",
    country_code: "175",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:YT",
    ccTLD: ".yt",
    phone_code: "+262"
  },
  {
    name: "Lesotho",
    name_official: "The Kingdom of Lesotho",
    alpha_2: "LS",
    alpha_3: "LSO",
    country_code: "426",
    currency_name: "Loti",
    currency_code: "LSL",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:LS",
    ccTLD: ".ls",
    phone_code: "+266"
  },
  {
    name: "Eritrea",
    name_official: "The State of Eritrea",
    alpha_2: "ER",
    alpha_3: "ERI",
    country_code: "232",
    currency_name: "Nakfa",
    currency_code: "ERN",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:ER",
    ccTLD: ".er",
    phone_code: "+291"
  },
  {
    name: "Belgium",
    name_official: "The Kingdom of Belgium",
    alpha_2: "BE",
    alpha_3: "BEL",
    country_code: "056",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:BE",
    ccTLD: ".be",
    phone_code: "+32"
  },
  {
    name: "France ",
    name_official: "The French Republic",
    alpha_2: "FR",
    alpha_3: "FRA",
    country_code: "250",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:FR",
    ccTLD: ".fr",
    phone_code: "+33"
  },
  {
    name: "Malta",
    name_official: "The Republic of Malta",
    alpha_2: "MT",
    alpha_3: "MLT",
    country_code: "470",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:MT",
    ccTLD: ".mt",
    phone_code: "+356"
  },
  {
    name: "Finland",
    name_official: "The Republic of Finland",
    alpha_2: "FI",
    alpha_3: "FIN",
    country_code: "246",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:FI",
    ccTLD: ".fi",
    phone_code: "+358"
  },
  {
    name: "Åland Islands",
    name_official: "Åland",
    alpha_2: "AX",
    alpha_3: "ALA",
    country_code: "248",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:AX",
    ccTLD: ".ax",
    phone_code: "+35818"
  },
  {
    name: "Lithuania",
    name_official: "The Republic of Lithuania",
    alpha_2: "LT",
    alpha_3: "LTU",
    country_code: "440",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:LT",
    ccTLD: ".lt",
    phone_code: "+370"
  },
  {
    name: "Moldova (the Republic of)",
    name_official: "The Republic of Moldova",
    alpha_2: "MD",
    alpha_3: "MDA",
    country_code: "498",
    currency_name: "Moldovan Leu",
    currency_code: "MDL",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:MD",
    ccTLD: ".md",
    phone_code: "+373"
  },
  {
    name: "Andorra",
    name_official: "The Principality of Andorra",
    alpha_2: "AD",
    alpha_3: "AND",
    country_code: "020",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:AD",
    ccTLD: ".ad",
    phone_code: "+376"
  },
  {
    name: "Austria",
    name_official: "The Republic of Austria",
    alpha_2: "AT",
    alpha_3: "AUT",
    country_code: "040",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:AT",
    ccTLD: ".at",
    phone_code: "+43"
  },
  {
    name: "Poland",
    name_official: "The Republic of Poland",
    alpha_2: "PL",
    alpha_3: "POL",
    country_code: "616",
    currency_name: "Zloty",
    currency_code: "PLN",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:PL",
    ccTLD: ".pl",
    phone_code: "+48"
  },
  {
    name: "Mexico",
    name_official: "The United Mexican States",
    alpha_2: "MX",
    alpha_3: "MEX",
    country_code: "484",
    currency_name: "Mexican Peso",
    currency_code: "MXN",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:MX",
    ccTLD: ".mx",
    phone_code: "+52"
  },
  {
    name: "Cuba",
    name_official: "The Republic of Cuba",
    alpha_2: "CU",
    alpha_3: "CUB",
    country_code: "192",
    currency_name: "Cuban Peso",
    currency_code: "CUP",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:CU",
    ccTLD: ".cu",
    phone_code: "+53"
  },
  {
    name: "Saint Martin (France)",
    name_official: "The Collectivity of Saint-Martin",
    alpha_2: "MF",
    alpha_3: "MAF",
    country_code: "663",
    currency_name: "Euro",
    currency_code: "EUR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:MF",
    ccTLD: ".mf",
    phone_code: "+590"
  },
  {
    name: "Singapore",
    name_official: "The Republic of Singapore",
    alpha_2: "SG",
    alpha_3: "SGP",
    country_code: "702",
    currency_name: "Singapore Dollar",
    currency_code: "SGD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:SG",
    ccTLD: ".sg",
    phone_code: "+65"
  },
  {
    name: "Fiji",
    name_official: "The Republic of Fiji",
    alpha_2: "FJ",
    alpha_3: "FJI",
    country_code: "242",
    currency_name: "Fiji Dollar",
    currency_code: "FJD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:FJ",
    ccTLD: ".fj",
    phone_code: "+679"
  },
  {
    name: "Kiribati",
    name_official: "The Republic of Kiribati",
    alpha_2: "KI",
    alpha_3: "KIR",
    country_code: "296",
    currency_name: "Australian Dollar",
    currency_code: "AUD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:KI",
    ccTLD: ".ki",
    phone_code: "+686"
  },
  {
    name: "Viet Nam ",
    name_official: "The Socialist Republic of Viet Nam",
    alpha_2: "VN",
    alpha_3: "VNM",
    country_code: "704",
    currency_name: "Dong",
    currency_code: "VND",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:VN",
    ccTLD: ".vn",
    phone_code: "+84"
  },
  {
    name: "Iraq",
    name_official: "The Republic of Iraq",
    alpha_2: "IQ",
    alpha_3: "IRQ",
    country_code: "368",
    currency_name: "Iraqi Dinar",
    currency_code: "IQD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:IQ",
    ccTLD: ".iq",
    phone_code: "+964"
  },
  {
    name: "Palestine, State of",
    name_official: "The State of Palestine",
    alpha_2: "PS",
    alpha_3: "PSE",
    country_code: "275",
    currency_name: "Jordanian dinar",
    currency_code: "JOD",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:PS",
    ccTLD: ".ps",
    phone_code: "+970"
  },
  {
    name: "Israel",
    name_official: "The State of Israel",
    alpha_2: "IL",
    alpha_3: "ISR",
    country_code: "376",
    currency_name: "New Israeli Sheqel",
    currency_code: "ILS",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:IL",
    ccTLD: ".il",
    phone_code: "+972"
  },
  {
    name: "Qatar",
    name_official: "The State of Qatar",
    alpha_2: "QA",
    alpha_3: "QAT",
    country_code: "634",
    currency_name: "Qatari Rial",
    currency_code: "QAR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:QA",
    ccTLD: ".qa",
    phone_code: "+974"
  },
  {
    name: "Nepal",
    name_official: "The Federal Democratic Republic of Nepal",
    alpha_2: "NP",
    alpha_3: "NPL",
    country_code: "524",
    currency_name: "Nepalese Rupee",
    currency_code: "NPR",
    currency_number: "978",
    country_code_iso: "ISO 3166-2:NP",
    ccTLD: ".np",
    phone_code: "+977"
  },
  {
    name: "Ukraine",
    name_official: "Ukraine",
    alpha_2: "UA",
    alpha_3: "UKR",
    country_code: "804",
    currency_name: "Hryvnia",
    currency_code: "UAH",
    currency_number: "980",
    country_code_iso: "ISO 3166-2:UA",
    ccTLD: ".ua",
    phone_code: "+380"
  },
  {
    name: "Gabon",
    name_official: "The Gabonese Republic",
    alpha_2: "GA",
    alpha_3: "GAB",
    country_code: "266",
    currency_name: "CFA Franc BEAC",
    currency_code: "XAF",
    currency_number: "981",
    country_code_iso: "ISO 3166-2:GA",
    ccTLD: ".ga",
    phone_code: "+241"
  },
  {
    name: "Pitcairn Islands",
    name_official: "The Pitcairn, Henderson, Ducie and Oeno Islands",
    alpha_2: "PN",
    alpha_3: "PCN",
    country_code: "612",
    currency_name: "New Zealand Dollar",
    currency_code: "NZD",
    currency_number: "985",
    country_code_iso: "ISO 3166-2:PN",
    ccTLD: ".pn",
    phone_code: "+64"
  },
  {
    name: "Brazil",
    name_official: "The Federative Republic of Brazil",
    alpha_2: "BR",
    alpha_3: "BRA",
    country_code: "076",
    currency_name: "Brazilian Real",
    currency_code: "BRL",
    currency_number: "986",
    country_code_iso: "ISO 3166-2:BR",
    ccTLD: ".br",
    phone_code: "+55"
  },
  {
    name: "South Africa",
    name_official: "The Republic of South Africa",
    alpha_2: "ZA",
    alpha_3: "ZAF",
    country_code: "710",
    currency_name: "Rand",
    currency_code: "ZAR",
    currency_number: "",
    country_code_iso: "ISO 3166-2:ZA",
    ccTLD: ".za",
    phone_code: "+27"
  },
  {
    name: "Jersey",
    name_official: "The Bailiwick of Jersey",
    alpha_2: "JE",
    alpha_3: "JEY",
    country_code: "832",
    currency_name: "The Jersey pound",
    currency_code: "JEP",
    currency_number: "",
    country_code_iso: "ISO 3166-2:JE",
    ccTLD: ".je",
    phone_code: "+441534"
  },
  {
    name: "Guernsey",
    name_official: "The Bailiwick of Guernsey",
    alpha_2: "GG",
    alpha_3: "GGY",
    country_code: "831",
    currency_name: "The Guernsey pound",
    currency_code: "GGP",
    currency_number: "",
    country_code_iso: "ISO 3166-2:GG",
    ccTLD: ".gg",
    phone_code: "+447"
  },
  {
    name: "Isle of Man",
    name_official: "The Isle of Man",
    alpha_2: "IM",
    alpha_3: "IMN",
    country_code: "833",
    currency_name: "The Isle of Man Pound",
    currency_code: "IMP",
    currency_number: "",
    country_code_iso: "ISO 3166-2:IM",
    ccTLD: ".im",
    phone_code: "+447"
  },
  {
    name: "Tuvalu",
    name_official: "Tuvalu",
    alpha_2: "TV",
    alpha_3: "TUV",
    country_code: "798",
    currency_name: "Tuvaluan dollar",
    currency_code: "TVD",
    currency_number: "",
    country_code_iso: "ISO 3166-2:TV",
    ccTLD: ".tv",
    phone_code: "+688"
  }
];
