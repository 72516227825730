import React from "react";
export default class Main extends React.Component {
  render() {
    return (
      <div>
        <a href={"https://" + window.location.host}>404</a>
      </div>
    );
  }
}
//"right wing artists "like leo kearse
//serous show
